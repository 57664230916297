import StickyBox from "react-sticky-box";
import Header from "@components/layout/header";
import TrendingNewsStacks from "@components/landing-page/trending-news-stacks";
import LandingHeroSection from "../why-use-lumi-page/hero-section";
import LatestNewsList from "./latest-news-list";
import TrendingVideosList from "./trending-videos-list";
import NewsStackSection from "./news-stack-section";
import SideBar from "@components/sidebar/sidebar";
import { HomePageQuery } from "@graphql/generated/graphql";
import { useRouter } from "next/router";

interface IHomePageProps {
  data: HomePageQuery;
  locale: string;
}

const HomePage: React.FunctionComponent<IHomePageProps> = ({
  data,
  locale,
}) => {
  const router = useRouter();

  if (router.isFallback) return <></>;

  const { allVideos } = data;

  return (
    <div className="mb-8">
      <Header showNewsStacks={true} isNewsStacksSticky={true} />
      <main className="flex-1">
        <div className="relative w-full overflow-hidden bg-[#e6eaff]">
          <div className="mx-auto max-w-7xl">
            <LandingHeroSection locale={locale} />
          </div>
        </div>
        <div className="mx-auto max-w-7xl space-y-4 divide-y divide-off-white px-4 md:mt-20 md:px-6">
          <NewsStackSection data={data} />

          {data.allCategories.length > 2 && (
            <div className="mx-auto max-w-7xl pt-8">
              <TrendingNewsStacks
                newsStacks={data.allCategories.slice(1)}
                isSearchNewsStacks={false}
              />
            </div>
          )}

          <div className="pt-14 md:pt-8">
            <TrendingVideosList allVideos={allVideos} />
          </div>

          <div className="flex-row items-start md:space-x-8 lg:flex">
            <div className="flex-1 pt-8">
              <LatestNewsList />
            </div>

            <StickyBox offsetTop={20} offsetBottom={20}>
              <SideBar showTrending={false} showQr={false} />
            </StickyBox>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
